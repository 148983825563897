import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'
import Layout from '../components/Layout'
import MetaImage from '../assets/images/meta/software-engineering-kpis.png'
import CTABlock from '../components/blocks/CTABlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import Stack from '../components/primitives/Stack'
import { BenchmarksTable } from '../components/BenchmarksTable'
import Box from '../components/primitives/Box'
import ColumnsBlock from '../components/blocks/ColumnsBlock'
import Blurb from '../components/system/Blurb'
import QuoteBlock from '../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import { RowVariant } from '../components/ComparisonTable'
import { Link } from 'gatsby'
import Br from '../components/system/Br'
import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/backgrounds/customers-dark.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }
    blurb1: file(
      relativePath: { eq: "images/product/investment-balance/multi-ic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb2: file(relativePath: { eq: "images/product/overview/target.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb3: file(relativePath: { eq: "images/product/surveys/question.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
  }
`

type BenchmarkExplanation = {
  key: string
  title: string
  tooltip?: string
  description: ReactNode
  rowVariant?: RowVariant
  isNested?: boolean
}

export const benchmarkExplanations = [
  {
    key: 'engineeringInvestment',
    title: 'Engineering investment',
    tooltip:
      'A sustainable engineering organization requires investment in non-product work.',
    description: (
      <>
        The distribution of engineering work, according to the{' '}
        <Link to="/blog/balancing-engineering-investments/">
          Balance Framework
        </Link>
      </>
    ),
  },
  {
    key: 'flowEfficiency',
    title: 'Flow efficiency',
    tooltip: 'Idle work suggests frequent interruptions and priority changes.',
    description:
      'The share of days an issue was actively worked on during its lifetime.',
    rowVariant: 'highlight',
  },
  {
    key: 'batchSize',
    title: 'Batch size',
    tooltip:
      'Smaller batches of work tend to move through the system faster and have less risk.',
    description: 'The number of lines in most changes.',
  },
  {
    key: 'leadTimeToChange',
    title: 'Change lead time',
    tooltip:
      'Long lead times might be a symptom of overly large increments, frequent interruptions, or too much WIP.',
    description:
      'How long it takes for a task to go from start to production. This includes:',
    rowVariant: 'highlightStart',
  },
  {
    key: 'prCycletime',
    title: 'PR cycle time',
    tooltip:
      'Speeding up code reviews is often the most effective way to improve cycle time.',
    description: (
      <>
        How long it takes for an open PR to be merged. This includes:
        <br />
        <br />
        Coding time
        <br />
        Review time
        <br />
        Merge time
      </>
    ),
    rowVariant: 'highlightMiddle',
    isNested: true,
  },
  {
    key: 'deploymentFrequency',
    title: 'Deployment frequency',
    tooltip:
      'Frequent deploys encourage smaller changes and enable rapid iteration.',
    description: 'How often new code gets released to users.',
    rowVariant: 'highlightEnd',
    isNested: true,
  },
  {
    key: 'timeToDeploy',
    title: 'Time to deploy',
    tooltip:
      'Rapid deploys enable quicker incident mitigation and encourage smaller changes.',
    description:
      'How long it takes for most approved changes to reach production.',
  },
] as const satisfies readonly BenchmarkExplanation[]

export type ExplanationKey = (typeof benchmarkExplanations)[number]['key']

export type BenchmarkColumn = {
  title: string
  values: Record<ExplanationKey, ReactNode>
}

const benchmarksConfig: BenchmarkColumn[] = [
  {
    title: 'Great',
    values: {
      engineeringInvestment: (
        <>
          10% KTLO, <Br />
          15% productivity, <Br />
          60% on new things & <Br />
          improving things
        </>
      ),
      flowEfficiency: (
        <>
          &gt; 95% of in-progress <Br />
          time is active
        </>
      ),
      batchSize: '< 200 lines',
      leadTimeToChange: '< 24 hours',
      prCycletime: '< 24 hours',
      deploymentFrequency: 'Continuously',
      timeToDeploy: '< 15 minutes',
    },
  },
  {
    title: 'Good',
    values: {
      engineeringInvestment: (
        <>
          30% KTLO, <Br />
          10% productivity
        </>
      ),
      flowEfficiency: (
        <>
          &gt; 70% of in-progress <Br />
          time is active
        </>
      ),
      batchSize: ' < 500 lines',
      leadTimeToChange: '< 3 working days',
      prCycletime: '< 3 working days',
      deploymentFrequency: 'Daily',
      timeToDeploy: '< 30 minutes',
    },
  },
  {
    title: 'Needs attention',
    values: {
      engineeringInvestment: (
        <>
          50% KTLO, <Br />
          or &lt; 10% productivity
        </>
      ),
      flowEfficiency: (
        <>
          &lt; 70% of in-progress <Br />
          time is active
        </>
      ),
      batchSize: '> 500 lines',
      leadTimeToChange: '> 7 working days',
      prCycletime: '> 5 working days',
      deploymentFrequency: 'Less than daily',
      timeToDeploy: '> 1 hour',
    },
  },
]

const BenchmarksPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Software engineering KPIs and goals"
      variant="dark"
      isNew
      description="Use a combination of your own baseline and Swarmia’s software engineering benchmarks to set KPIs and goals for software development."
      metaImage={MetaImage}
    >
      <HeroBlock
        title="A better way to track software engineering KPIs & goals"
        content="Use a combination of your own baseline and Swarmia’s software engineering benchmarks to set KPIs and goals for software development."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box marginBottom={48}>
        <LeadBlock
          heading="Set realistic KPIs for software development"
          content="When you’re drafting goals for your engineering team, consider the team’s current baseline as well as industry benchmarks. Use the table below to get benchmarks and connect to Swarmia to see how your team fares today."
        />
      </Box>
      <Stack maxWidth={1400} marginX="auto" space={24}>
        <BenchmarksTable benchmarks={benchmarksConfig} />
      </Stack>
      <QuoteBlock person="tim" />
      <Box marginTop={48}>
        <CTABlock title="Find your engineering organization’s biggest opportunities for improvement." />
      </Box>
      <LeadBlock
        heading="Track KPIs across all three areas of engineering effectiveness"
        content="Swarmia gives everyone in your engineering organization visibility into three types of insights: business outcomes, developer productivity, and developer experience."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Business outcomes"
            text="Keep track of cross-team engineering initiatives and balance your investment."
            href="/product/business-outcomes/"
            target="_self"
            image={getImage(data.blurb1)}
          />,
          <Blurb
            key="2"
            title="Developer productivity"
            text="Measure and improve proven developer productivity metrics like DORA and SPACE."
            href="/product/developer-productivity/"
            target="_self"
            image={getImage(data.blurb2)}
          />,
          <Blurb
            key="3"
            title="Developer experience"
            text="Run developer experience surveys and combine survey data with system metrics."
            href="/product/developer-experience/"
            target="_self"
            image={getImage(data.blurb3)}
          />,
        ]}
      />
      <QuoteBlock person="walter" />
      <CTABlock
        title={
          <>Get your baseline metrics by connecting&nbsp;to&nbsp;Swarmia.</>
        }
      />
      <FeaturedBlogArticles
        heading="More from the swarmia blog"
        articles={[
          '/blog/engineering-benchmarks/',
          '/blog/change-lead-time/',
          '/blog/flow-efficiency/',
        ]}
        newStyles
      />
    </Layout>
  )
}

export default BenchmarksPage
